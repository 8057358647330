import React, { useState, useRef, useEffect, useCallback } from 'react';
import './player.css';
import { IoMdPlayCircle } from 'react-icons/io';
import { FaStop, FaVolumeUp } from 'react-icons/fa';
import { fetchNowPlayingData } from '../fetch';

function Player() {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [volume, setVolume] = useState(1); // Default volume
  const [nowPlaying, setNowPlaying] = useState({ title: 'Unknown Title', author: 'Unknown Artist', image: 'https://via.placeholder.com/100' });

  // Memoize the togglePlayPause function
  const togglePlayPause = useCallback(() => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
    setIsPlaying((prev) => !prev); // Update state with previous value
  }, [isPlaying]); // Depend on isPlaying

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value); // Ensure it's a number
    setVolume(newVolume);
    audioRef.current.volume = newVolume;
    localStorage.setItem('audioVolume', newVolume); // Save volume to localStorage
  };

  useEffect(() => {
    // Load volume from localStorage if it exists
    const savedVolume = localStorage.getItem('audioVolume');
    if (savedVolume) {
      const volumeValue = parseFloat(savedVolume);
      setVolume(volumeValue);
      audioRef.current.volume = volumeValue; // Set audio element volume
    }

    const fetchData = async () => {
      try {
        const data = await fetchNowPlayingData();
        const { title, author, image } = data; // Adjust based on actual API response
        setNowPlaying({ title: title || 'Unknown Title', author: author || 'Unknown Artist', image: image || 'https://via.placeholder.com/100' });

        // Set MediaSession metadata for casting
        if ('mediaSession' in navigator) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: nowPlaying.title,
            artist: nowPlaying.author,
            album: 'Album Name', // You can adjust this based on your API
            artwork: [
              { src: nowPlaying.image, sizes: '96x96', type: 'image/png' },
              { src: nowPlaying.image, sizes: '128x128', type: 'image/png' },
              { src: nowPlaying.image, sizes: '192x192', type: 'image/png' },
              { src: nowPlaying.image, sizes: '512x512', type: 'image/png' },
            ],
          });

          // Set playback actions
          navigator.mediaSession.setActionHandler('play', togglePlayPause);
          navigator.mediaSession.setActionHandler('pause', togglePlayPause);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 10000);
    
    return () => clearInterval(interval);
  }, [nowPlaying.title, nowPlaying.author, nowPlaying.image, togglePlayPause]); // Add togglePlayPause to dependencies

  return (
    <div className="App">
      <div className="player">
        <div className="album-art">
          <img src={nowPlaying.image} alt="Album Art" />
          <div className="song-info">
            <div className="song-title">{nowPlaying.title}</div>
            <div className="song-artist">{nowPlaying.author}</div>
          </div>
        </div>
        <div className="play-pause-container">
          <button className="play-pause" onClick={togglePlayPause}>
            {isPlaying ? <FaStop size={30} /> : <IoMdPlayCircle size={30} />}
          </button>
        </div>
        <div className="volume-control">
          <input
            type="range"
            min="0"
            max="1"
            step="0.01"
            value={volume}
            onChange={handleVolumeChange}
            className="volume-slider"
          />
          <FaVolumeUp size={20} className="volume-icon" />
        </div>
        <audio ref={audioRef} src="https://stream.gglvxd.eu.org/radio" />
      </div>
    </div>
  );
}

export default Player;
